export const pageview = (url: string) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  window.gtag('config', process.env.GA_TRACKING_ID, {
    page_path: url,
  });
};

interface GTagEvent {
  action: string;
  category: string;
  label: string;
  value: number;
  nonInteraction?: boolean;
}

export const event = ({
  action,
  category: event_category,
  label: event_label,
  value,
  nonInteraction: non_interaction,
}: GTagEvent) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  window.gtag('event', action, {
    event_category,
    event_label,
    value,
    non_interaction,
  });
};
