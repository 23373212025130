import { useCallback, useEffect, useState } from 'react';

export const useInstallPrompt = () => {
  const [promptInstall, setPromptInstall] = useState(null);

  const onBeforeInstallPrompt = useCallback((event: Event) => {
    event.preventDefault();
    setPromptInstall(event);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);

    return () =>
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
  }, [onBeforeInstallPrompt]);

  return promptInstall;
};
