import { useRouter } from 'next/dist/client/router';
import { useCallback, useEffect } from 'react';
import { pageview } from '~/utils/gtag';

export const useGAPageView = () => {
  const router = useRouter();

  const onRouteChange = useCallback(url => {
    pageview(url);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    // pageview(router.asPath);

    router.events.on('routeChangeComplete', onRouteChange);

    return () => {
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, [router.events, onRouteChange]);
};
