import React, { FC } from 'react';
import Head from 'next/head';
import NextApp, { AppProps, NextWebVitalsMetric } from 'next/app';
import { reset } from 'styled-reset';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { useInstallPrompt } from '~/hooks/use-install-prompt';
import { useGAPageView } from '~/hooks/use-google-analytics';
import { event } from '~/utils/gtag';

const Meta: FC = () => (
  <Head>
    <title>hitbox</title>

    <link href="/static/manifest.json" rel="manifest" />
    <link href="/static/apple-touch-icon.png" rel="apple-touch-icon" />

    <meta content="#fff" name="theme-color" />
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1.0,maximum-scale=1.0,viewport-fit=cover"
    />
  </Head>
);

const theme = {};
const GlobalStyle = createGlobalStyle`
    ${reset};
    body {
      overflow: hidden;
    }
`;

const Hitbox = ({ Component, pageProps }: AppProps) => {
  useInstallPrompt();
  useGAPageView();
  return (
    <>
      <Meta />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
};

export default class App extends NextApp {
  render() {
    return <Hitbox {...this.props} />;
  }
}

export const reportWebVitals = ({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  event({
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js Custom Metric',
    action: name,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    label: id,
    nonInteraction: true,
  });
};
